import React, { useState, useEffect, useRef } from 'react'
import httpClient from '../../httpClient'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import './PopupwindowCustomerInfoMobile.css'
import './PopupwindowMobile'
import { PiCaretLeftThin } from "react-icons/pi"
import FormDropdownPopup from './FormDropdownPopup'
import AlertPopupMobile from './AlertPopupMobile'
import { MdOutlineArrowDropDown } from "react-icons/md"
import HamburgerMenu from './HamburgerMenu'
import PopupwindowSideMenu from './PopupwindowSideMenu'
import { PiPencilLineThin } from "react-icons/pi"
import { changeThemeColor } from "../../components/helpers/GlobalFunctions"
import CheckBoxCustomDetails from "../../components/CheckBoxCustomDetails"
import InputCustomDetails from "../../components/InputCustomDetails"
import DropDownCustomDetails from "../../components/DropDownCustomDetails"
import { updateSalesPipeNeedsRefresh } from '../../store/salesPipePage'
import { fetchSuppliers } from '../../store/userThunks'
import LoadingSpinner from '../placeholders/LoadingSpinner'
import CheckBoxStatic from '../CheckBoxStatic'
import PopupwindowMobile from './PopupwindowMobile'
import Label from '../Label'
import SearchBar from '../SearchBar'
import CircularProgress from '@mui/material/CircularProgress'
import CheckBox from '../CheckBox'
import { formatDateWithTime } from '../helpers/FormatDate'
import { PiCaretRightThin } from "react-icons/pi"
import PopupwindowCreateActivityMobile from './PopupwindowCreateActivityMobile'
import PopupwindowFiltersMobile from './PopupwindowFiltersMobile'
import { CiFilter } from "react-icons/ci"
import SearchBarList from '../SearchBarList'
import { PiPlusThin } from "react-icons/pi"
import { customerContactRhythmsArray } from '../../assets/Data'
import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import PopupDropdown from '../PopupDropdown'
import PopupDropdownMobile from './PopupDropdownMobile'
import CustomerContentGrid from '../CustomerContentGrid'
import { showAlertPopupMobile } from '../helpers/NotificationHelpers'

function PopupwindowCustomerInfoMobile({ selectedCustomer, fetchCustomers, setIsActive, isActive }) {
  const [loadingCustomDetails, setLoadingCustomDetails] = useState(false)
  const [loadingProductAvailability, setLoadingProductAvailability] = useState(true)
  const [loadingActivities, setLoadingActivities] = useState(false)
  const [loadingSaveButton, setLoadingSaveButton] = useState(false)
  const [customer, setCustomer] = useState(selectedCustomer)
  const [dropdownPopupIsOpen, setDropdownPopupIsOpen] = useState(false)
  const [dropdownList, setDropdownList] = useState([])
  const [dropdownID, setDropdownID] = useState("")
  const [edit, setEdit] = useState(false)
  const [sideMenuOpen, setSideMenuOpen] = useState(false)
  const [selectedMenu, setSelectedMenu] = useState("CONTACT")
  const [customDetails, setCustomDetails] = useState([])
  const [changesMadeCustomDetails, setChangesMadeCustomDetails] = useState(false)
  const [changesMadeAvailability, setChangesMadeAvailability] = useState(false)
  const [stateData, setStateData] = useState({})
  const [productAvailability, setProductAvailability] = useState([])
  const [myProductSuppliers, setMyProductSuppliers] = useState([])
  const [vendors, setVendors] = useState([])
  const [suppliersSelectedProduct, setsuppliersSelectedProduct] = useState({})
  const [supplierUpdateType, setSupplierUpdateType] = useState("")
  const [searchWordSuppliers, setSearchWordSuppliers] = useState("")
  const [activities, setActivities] = useState([])
  const [createActivityPopupIsActive, setCreateActivityPopupIsActive] = useState(false)
  const [selectedCustomerID, setSelectedCustomerID] = useState(null)
  const [selectedActivity, setSelectedActivity] = useState({})
  const [editActivity, setEditActivity] = useState(false)
  const [createReport, setCreateReport] = useState(false)
  const [myBrands, setMyBrands] = useState([])
  const [filtersPopupIsActive, setFiltersPopupIsActive] = useState(false)
  const [loadingCustomerSupplier, setLoadingCustomerSupplier] = useState(false)
  const [customerSuppliers, setCustomerSuppliers] = useState([])
  const [newSupplier, setNewSupplier] = useState(null)
  const [newAlias, setNewAlias] = useState(null)
  const [customerContentImages, setCustomerContentImages] = useState([])
  const [customerContentFiles, setCustomerContentFiles] = useState([])
  const [supplierSelectPopupIsActive, setSupplierSelectPopupIsActive] = useState(false)

  const [availabilityState, setAvailabilityState] = useState({
    "productsPage": 1,
    "materialsPage": 1,
    "limit": 25,
    "productsHasMore": true,
    "materialsHasMore": true,
    "productsAvailability": [],
    "materialsAvailability": [],
    "loadingMoreProducts": false,
    "loadingMoreMaterials": false,
  })
  const [availabilityFilters, setAvailabilityFilters] = useState({
    "searchWord": "",
    "hideUnavailable": false
  })
  const [contactRhythm, setContactRhythm] = useState()

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const parentDivRef = useRef(null)
  const saveButtonRef = useRef(null)
  const createCustomerPopupRef = useRef(null)

  const customerTypesArray = useSelector(state => state.user.customer_types)
  const suppliers = useSelector(state => state.user.suppliers)
  const userInfo = useSelector(state => state.user.userInfo)

  const arrayOfTypes = Object.keys(customerTypesArray)
  const arrayOfSubTypes = customerTypesArray[customer.type] || []
  const now = new Date()

  const fetchCustomCustomerDetails = async () => {
    setLoadingCustomDetails(true)
    const response = await httpClient.get(process.env.REACT_APP_API_URL + "/api/user/classification/custom-user-details/get?customerID=" + selectedCustomer.id)
    setCustomDetails(response.data)

    setLoadingCustomDetails(false)
  }

  const fetchCustomerSuppliers = async () => {
    setLoadingCustomerSupplier(true)
    try {
      const response = await httpClient.get(process.env.REACT_APP_API_URL + `/api/customer/get-suppliers?customerID=${selectedCustomer.id}`)
      setCustomerSuppliers(response.data)
    } catch (error) {
      console.error('Error fetching customer suppliers:', error)
    }
    setLoadingCustomerSupplier(false)
  }

  const fetchCustomerContactRhythm = async () => {
    const response = await httpClient.get(process.env.REACT_APP_API_URL + "/api/user/classification/customer-contact-rhythm/get?customerID=" + selectedCustomer.id)
    setContactRhythm(response.data)
  }

  const fetchAvailability = async () => {
    setLoadingProductAvailability(true)

    const data = {
      ...availabilityState,
      "filters": availabilityFilters,
    }

    const response = await httpClient.post(process.env.REACT_APP_API_URL + `/api/user/classification/product-availability/get?customerID=${selectedCustomer.id}`, data)
    const responseData = response.data

    const updatedState = { ...availabilityState }

    updatedState["productsAvailability"] = responseData.availabilities.products
    updatedState["productsHasMore"] = responseData.has_more_products
    updatedState["loadingMoreProducts"] = false

    updatedState["materialsAvailability"] = responseData.availabilities.materials
    updatedState["materialsHasMore"] = responseData.has_more_materials
    updatedState["loadingMoreMaterials"] = false

    setAvailabilityState(updatedState)
    setLoadingProductAvailability(false)
  }

  const fetchMyProductSuppliers = async () => {
    const responseProducts = await httpClient.get(process.env.REACT_APP_API_URL + "/api/customer/get-suppliers-of-my-products?customerID=" + selectedCustomer.id)
    setMyProductSuppliers(responseProducts.data)
  }

  const fetchActivitiesOfCustomer = async () => {
    setLoadingActivities(true)

    const response = await httpClient.get(process.env.REACT_APP_API_URL + "/api/activities/get-all-activities-of-customer?customerID=" + selectedCustomer.id)
    setActivities(response.data)

    setLoadingActivities(false)
  }

  const fetchMyBrands = async () => {
    const response = await httpClient.get(process.env.REACT_APP_API_URL + `/api/salesforce/get-my-brandowners`)

    setMyBrands(response.data)
  }

  const fetchVendors = async () => {
    const response = await httpClient.get(process.env.REACT_APP_API_URL + "/api/customer/get-vendors?customerID=" + selectedCustomer.id)
    const brandowners = new Set(myProductSuppliers.map(product => product.brandowner_vendor_id))

    const vendorsList = response.data.filter(vendor =>
      vendor.is_brandowner && Array.from(brandowners).includes(vendor.id) ||
      !vendor.is_brandowner && vendor.company_name != 'STOQUP'
    )
    const pcOfCustomer = customer.delivery_address_pc

    vendorsList.sort((vendor1, vendor2) => {
      // Sort by is_brandowner (true before false)
      if (vendor1.is_brandowner !== vendor2.is_brandowner) {
        return vendor1.is_brandowner ? -1 : 1;
      }

      // Sort by is_pseudo (true before false)
      if (vendor1.is_pseudo !== vendor2.is_pseudo) {
        return vendor1.is_pseudo ? -1 : 1;
      }

      // Sort by postal code (closest to pcOfCustomer)
      const vendor1Distance = Math.abs(vendor1.postal_code - pcOfCustomer);
      const vendor2Distance = Math.abs(vendor2.postal_code - pcOfCustomer);

      return vendor1Distance - vendor2Distance;
    })

    setVendors(vendorsList)
  }

  const fetchCustomerContent = async () => {
    const response = await httpClient.get(process.env.REACT_APP_API_URL + `/api/customer/content/get?customerID=${selectedCustomer.id}`)
    setCustomerContentImages(response.data.images)
    setCustomerContentFiles(response.data.files)    
  }

  useEffect(() => {
    const sanitizedCustomer = Object.keys(selectedCustomer).reduce((acc, key) => {
      // Replace null values with an empty string to avoid error in console
      acc[key] = selectedCustomer[key] === null ? '' : selectedCustomer[key]
      return acc
    }, {})

    setCustomer(sanitizedCustomer)

    if (selectedCustomer && Object.keys(selectedCustomer).length > 0) {
      setSelectedCustomerID(selectedCustomer.id)

      const fetchPromises = [
        fetchCustomCustomerDetails(),
        fetchAvailability(),
        fetchActivitiesOfCustomer(),
        fetchMyBrands(),
        fetchCustomerContactRhythm(),
        fetchCustomerContent()
      ];

      if (["wholesaler"].includes(userInfo.app_version)) {
        fetchPromises.push(fetchSuppliers(), fetchCustomerSuppliers())
      } else if (["brandowner"].includes(userInfo.app_version)) {
        fetchPromises.push(fetchVendors(), fetchMyProductSuppliers)
      }

      Promise.all(fetchPromises)
        .then(() => {
          // Handle any post-fetch logic here if needed
        })
        .catch(error => {
          console.error('Error fetching data:', error);
        });
    }
  }, [selectedCustomer])

  useEffect(() => {
    if (customer.type == "wholesaler") {
      setCustomer(prev => ({
        ...prev,
        is_supplier: true
      }))
    }
  }, [customer.type])

  useEffect(() => {
    if (Object.keys(suppliersSelectedProduct).length > 0 | supplierUpdateType != "") {
      fetchVendors()
    }
  }, [suppliersSelectedProduct])

  useEffect(() => {
    if (sideMenuOpen) {
      changeThemeColor("#282828")
    } else {
      changeThemeColor("#EBEBEB")
    }
  }, [sideMenuOpen])

  useEffect(() => {
    fetchAvailability()
  }, [availabilityState.productsPage, availabilityState.materialsPage])

  useEffect(() => {
    setLoadingProductAvailability(true)

    fetchAvailability()
      .then(() => setLoadingProductAvailability(false))

  }, [availabilityFilters.searchWord])

  useEffect(() => {
    setLoadingProductAvailability(true)

    fetchAvailability()
      .then(() => {
        setLoadingProductAvailability(false)
      })
  }, [availabilityFilters.hideUnavailable])

  const setCustomDetailsDropdown = (e, id, value) => {
    setCustomDetails(prevDetails => {
      return prevDetails.map((detail, index) => {
        if (index === id) {
          return { ...detail, value: value }
        }
        return detail
      })
    })
  }

  function renderCustomerDetail(details, parentIndex) {
    return details.map((detail, index) => {
      switch (detail.type) {
        case 'checkmark':
          return <CheckBoxCustomDetails
            key={index}
            setChangesMade={setChangesMadeCustomDetails}
            parentIndex={parentIndex}
            index={index}
            text={detail.title}
            defaultState={detail.value}
            state={customDetails}
            setState={setCustomDetails}
            textPosition="left"
            margin="0 0 15px 0"
            style="mobile"
          />
        case 'input':
          return <InputCustomDetails
            key={index}
            setChangesMade={setChangesMadeCustomDetails}
            parentIndex={parentIndex}
            index={index}
            text={detail.title}
            defaultState={detail.value}
            state={customDetails}
            setState={setCustomDetails}
            inputBoxSize={100}
            fontSize="0.75rem"
            textPosition=""
            style="mobile"
          />
        case 'dropdown':
          return <PopupDropdownMobile
            id={index}
            value={detail.value}
            setValue={setCustomDetailsDropdown}
            list={detail.dropdown_values}
            placeholder={detail.title}
            type={detail.type}
            stateData={customDetails}
            stateType="controlled"
            setChangesMade={setChangesMadeCustomDetails}
          />
        default:
          return null
      }
    })
  }

  const handleClosePopup = () => {
    setIsActive(false)
  }

  const handleSave = () => {

    switch (selectedMenu) {
      case "CONTACT":
        handleSaveCustomer()
        break
      case "DETAILS":
        handleSaveCustomDetails()
        break
      case "AVAILABILITY":
        handleSaveAvailability()
        break
    }
  }

  const handleSaveAvailability = async () => {
    setLoadingSaveButton(true)

    const button = saveButtonRef.current
    button.disabled = true

    // convert the state to fit the api request 

    const transformAvailability = (availability) => {
      return availability.map(item => ({
        ...item,
        product_id: item.id
      }))
    }

    const products = transformAvailability(availabilityState.productsAvailability)
    const materials = transformAvailability(availabilityState.materialsAvailability)

    const responseProducts = await httpClient.post(process.env.REACT_APP_API_URL + `/api/user/classification/product-availability/change?customerID=${selectedCustomer.id}`, products)
    const responseMaterials = await httpClient.post(process.env.REACT_APP_API_URL + `/api/user/classification/product-availability/change?customerID=${selectedCustomer.id}`, materials)

    setEdit(false)
    button.disabled = false
    setLoadingSaveButton(false)
    dispatch(updateSalesPipeNeedsRefresh(true))
  }

  const handleSaveCustomDetails = async () => {
    const button = saveButtonRef.current
    button.disabled = true

    const data = {
      customerID: customer.id,
      customDetails
    }

    const response = await httpClient.post(process.env.REACT_APP_API_URL + `/api/user/classification/custom-user-details/update`, data)
    console.log(response.data)

    setChangesMadeCustomDetails(false)
    fetchCustomCustomerDetails()
    button.disabled = false
  }

  const handleSaveCustomer = async () => {
    const button = saveButtonRef.current
    button.disabled = true
    setLoadingSaveButton(true)

    const emailValidation = /\S+@\S+\.\S+/

    if (
      customer.business_name == ""
    ) {
      showAlertPopupMobile({
        type: "error",
        message: "Please complete required fields"
      })

      button.disabled = false
      setLoadingSaveButton(false)
      return
    }

    if (customer.email) {
      if (!emailValidation.test(customer.email)) {
        showAlertPopupMobile({
          type: "error",
          message: "Incorrect email value"
        })

        button.disabled = false
        setLoadingSaveButton(false)
        return
      }
    }

    if (customer.sales_email) {
      if (!emailValidation.test(customer.email)) {
        showAlertPopupMobile({
          type: "error",
          message: "Incorrect email value"
        })

        button.disabled = false
        setLoadingSaveButton(false)
        return
      }
    }
    const response = await httpClient.post(process.env.REACT_APP_API_URL + "/api/user/update", customer)

    if (response.status === 200) {

      // if customer is supplier fetch supplier list again
      if (customer.is_supplier) {
        fetchSuppliers()
      }

      fetchCustomers()
      setEdit(false)
    }

    button.disabled = false
    setLoadingSaveButton(false)
  }

  const handleOnchangeInput = (e) => {
    const thisInputElement = e.currentTarget
    const value = thisInputElement.value

    const phoneRegex = /^[0-9-()+\s]+$/

    // Check if the value matches the phone number format
    if (thisInputElement.name === 'tel_number' && value && !phoneRegex.test(value)) {
      return
    }

    const newCustomerState = { ...customer }
    newCustomerState[thisInputElement.name] = value
    setCustomer(newCustomerState)
  }

  const handleSetNewCustomerState = async (id, value, stateData = null) => {


    if (id == "customer-contact-rhythm") {
      const data = {
        "customerID": selectedCustomer.id,
        "contactRhythm": value
      }

      const response = await httpClient.post(process.env.REACT_APP_API_URL + `/api/user/classification/custom-user-details/update?type=contact-rhythm`, data)
      console.log(response.data)

      if (response.status == 200) {
        setContactRhythm(prev => ({
          ...prev,
          rhythm: value
        }))
      } else {
        showAlertPopupMobile({
          type: "error",
          message: `Error updating contact rhythm: ${response.data}`
        })
      }
    }

    if (stateData & Object.keys(stateData).length > 0) {

      if (stateData.type == "custom-details") {
        const newStateObj = JSON.parse(JSON.stringify(customDetails))
        newStateObj[stateData.parentIndex]["custom_details"][stateData.index].value = value
        setCustomDetails(newStateObj)
      }

    } else {
      const newCustomerObj = { ...customer }

      switch (id) {
        case "customer-type":
          newCustomerObj["type"] = value
          newCustomerObj["sub_type"] = null
          break

        case "customer-sub-type":
          newCustomerObj["sub_type"] = value
          break
      }

      setCustomer(newCustomerObj)
    }

  }

  const handleOnClickPopup = (e, type) => {

    switch (type) {
      case "type":
        setDropdownList(arrayOfTypes)
        setDropdownID("customer-type")
        setDropdownPopupIsOpen(true)
        break

      case "sub_type":
        setDropdownList(arrayOfSubTypes)
        setDropdownID("customer-sub-type")
        setDropdownPopupIsOpen(true)
        break

      case "contact_rhythm":
        setDropdownList(customerContactRhythmsArray)
        setDropdownID("customer-contact-rhythm")
        setDropdownPopupIsOpen(true)
        break
    }
  }

  const handleDuplicate = (e, type) => {
    e.preventDefault()

    let newCustomerObj = { ...customer }

    switch (type) {
      case "company_name":
        newCustomerObj.company_name = customer.business_name
        break

      case "invoice_email":
        newCustomerObj.invoice_email = customer.email
        break

      case "address":
        newCustomerObj.delivery_address_street = customer.invoice_address_street
        newCustomerObj.delivery_address_nr = customer.invoice_address_nr
        newCustomerObj.delivery_address_pc = customer.invoice_address_pc
        newCustomerObj.delivery_address_place = customer.invoice_address_place
        newCustomerObj.delivery_address_country = customer.invoice_address_country
        break
    }

    setCustomer(newCustomerObj)
  }

  const handleCancel = () => {
    switch (selectedMenu) {
      case "CONTACT":
        setCustomer(selectedCustomer)
        setEdit(false)
        break
      case "DETAILS":
        fetchCustomCustomerDetails()
        setChangesMadeCustomDetails(false)
        break
      case "AVAILABILITY":
        fetchAvailability()
        setEdit(false)
        break
    }
  }

  const onClickCheckAvailability = async (event) => {
    const productID = event.currentTarget.parentElement.getAttribute("data-product-id")
    const productType = event.currentTarget.parentElement.getAttribute("data-product-type")

    const newAvailabilityState = { ...availabilityState }

    if (productType == 'material') {
      const productIndex = newAvailabilityState.materialsAvailability.findIndex(product => product.id == productID)
      newAvailabilityState.materialsAvailability[productIndex].availability = !newAvailabilityState.materialsAvailability[productIndex].availability
    } else if (productType == 'product') {
      const productIndex = newAvailabilityState.productsAvailability.findIndex(product => product.id == productID)
      newAvailabilityState.productsAvailability[productIndex].availability = !newAvailabilityState.productsAvailability[productIndex].availability
    }

    setProductAvailability(newAvailabilityState)

    setEdit(true)
  }

  const handleOnclickSelectSupplier = (e, selectedProduct, type = "") => {
    setSupplierSelectPopupIsActive(true)
    setsuppliersSelectedProduct(selectedProduct)
    setSupplierUpdateType(type)
  }

  const handleSaveSupplier = async (e) => {
    const vendorID = e.currentTarget.getAttribute("data-vendor-id")

    const response = await httpClient.post(process.env.REACT_APP_API_URL + `/api/customer/update-supplier-of-product?productID=${suppliersSelectedProduct.product_id}&vendorID=${vendorID}&customerID=${selectedCustomer.id}&type=${supplierUpdateType}`)
    console.log(response.data)

    fetchMyProductSuppliers()
    setSupplierSelectPopupIsActive(false)
  }

  const handlePreCheckDone = async (id) => {
    const activity = activities.find(obj => obj.id == id)

    if (activity.done) {
      setLoadingActivities(true)

      const data = {
        "ids": [id]
      }

      const response = await httpClient.post(process.env.REACT_APP_API_URL + `/api/activities/change-done-status?id=${id}`, data)
      console.log(response.data)

      fetchActivitiesOfCustomer()
        .then(() => {
          setLoadingActivities(false)
        })
        .catch(error => {
          console.error('Error fetching activities:', error)
          setLoadingActivities(false)
        })

    } else {
      activity.done = true
      setCreateReport(true)
      setCreateActivityPopupIsActive(true)
      setSelectedActivity(activity)
    }

  }

  const handleEditActivity = (event, id) => {
    event.stopPropagation()

    setEditActivity(true)

    const activity = activities.find(obj => obj.id == id)
    setSelectedActivity(activity)

    setCreateActivityPopupIsActive(true)
  }

  const handleOnClickLoadMore = (type) => {

    const updatedState = { ...availabilityState }

    if (type == 'products') {
      updatedState['loadingMoreProducts'] = true
      updatedState['productsPage'] = updatedState['productsPage'] + 1
    } else if (type == 'materials') {
      updatedState['loadingMoreMaterials'] = true
      updatedState['materialsPage'] = updatedState['materialsPage'] + 1
    }

    setAvailabilityState(updatedState)
  }

  const handleIsSupplier = (e) => {
    const newCustomerObj = { ...customer }
    newCustomerObj.is_supplier = !newCustomerObj.is_supplier
    setCustomer(newCustomerObj)
  }

  const handleAddSupplier = async (event) => {
    event.stopPropagation()
    const button = document.getElementById("add-supplier-btn")
    button.disabled = true

    if (!newSupplier) {
      button.disabled = false
      return
    }

    try {
      const data = {
        customerID: selectedCustomer.id,
        supplierID: newSupplier.id,
        alias: newAlias || null
      }

      await httpClient.post(process.env.REACT_APP_API_URL + `/api/customer/add-supplier`, data)
      await fetchCustomerSuppliers()
      setNewSupplier(null)
      setNewAlias("")
    } catch (error) {
      console.error('Error adding supplier:', error)
    }
    
    button.disabled = false
  }

  // Function to handle deleting a supplier
  const handleDeleteSupplier = async (event, supplierID) => {
    event.stopPropagation()
    const button = event.currentTarget
    button.disabled = true

    await httpClient.delete(process.env.REACT_APP_API_URL + `/api/customer/delete-supplier?customerID=${selectedCustomer.id}&supplierID=${supplierID}`)
    fetchCustomerSuppliers()
    button.disabled = false
  }

  return (
    <div className={`popup_window__container ${isActive ? "active" : ""}`} id='popup-window-customer-info'>
      <FormDropdownPopup
        id={dropdownID}
        list={dropdownList}
        isOpen={dropdownPopupIsOpen}
        setIsOpen={setDropdownPopupIsOpen}
        setValue={handleSetNewCustomerState}
        stateData={stateData}
      />

      <PopupwindowSideMenu
        id={"customer-info-menu"}
        isActive={sideMenuOpen}
        setIsActive={setSideMenuOpen}
        selectedMenu={selectedMenu}
        setSelectedMenu={setSelectedMenu}
      />

      <PopupwindowFiltersMobile
        id={"popup-window-filters--customer-info-page"}
        isActive={filtersPopupIsActive}
        setIsActive={setFiltersPopupIsActive}
        filters={availabilityFilters}
        updateFilters={setAvailabilityFilters}
        stateType="useState"
      />

      <PopupwindowCreateActivityMobile
        isActive={createActivityPopupIsActive}
        setIsActive={setCreateActivityPopupIsActive}
        popupID={"popout-create-activity"}
        selectedCustomerID={selectedCustomerID}
        setSelectedCustomerID={setSelectedCustomerID}
        // setNewCustomerPopupIsActive={setNewCustomerPopupIsActive}
        fetchActivities={fetchActivitiesOfCustomer}
        myBrands={myBrands}
        selectedActivity={selectedActivity}
        editActivity={editActivity}
        setEditActivity={setEditActivity}
        createReport={createReport}
        setCreateReport={setCreateReport}
        setLoading={setLoadingActivities}
      />

      <PopupwindowMobile
        id="customer-info-supplier-select-popup"
        isActive={supplierSelectPopupIsActive}
        setIsActive={setSupplierSelectPopupIsActive}
      >
        <div className='supplier_list__wrapper'>
          <h1>
            {
              supplierUpdateType != "" ?
                `ALL ${supplierUpdateType}`
                :
                `${suppliersSelectedProduct.product_name} ${suppliersSelectedProduct.product_volume}`
            }
          </h1>
          <SearchBar
            searchWord={searchWordSuppliers}
            setSearchWord={setSearchWordSuppliers}
            mobile={true}
            margin={"0 0 20px 0"}
          />
          <div className='list_2clmns__container--mobile'>
            {
              vendors
                .filter(supplier => supplier.company_name.toLowerCase().includes(searchWordSuppliers.toLowerCase()))
                .map((vendor, index) => (
                  <div className='list_2clmns__row--mobile' onClick={handleSaveSupplier} data-vendor-id={vendor.id} key={index}>
                    <div className='list_2clmns__field--mobile'>{vendor.company_name}</div>
                    <div className='list_2clmns__field--mobile'>
                      {
                        vendor.is_brandowner ?
                          <div className='label__vendors'><Label title="Brandowner" labelColor="blue" fontSize={"1rem"} highContrast={true} /></div> :
                          vendor.is_pseudo ?
                            <div className='label__vendors'><Label title="Added by customer" labelColor="green" fontSize={"1rem"} highContrast={true} /></div> :
                            <div className='label__vendors'></div>
                      }
                    </div>
                  </div>
                ))
            }
          </div>
        </div>
      </PopupwindowMobile>

      <PopupwindowCreateActivityMobile
        isActive={createActivityPopupIsActive}
        setIsActive={setCreateActivityPopupIsActive}
        popupID={"popout-create-activity"}
        selectedCustomerObject={selectedCustomer}
        // setNewCustomerPopupIsActive={setNewCustomerPopupIsActive}
        fetchActivities={fetchActivitiesOfCustomer}
        myBrands={myBrands}
        selectedActivity={selectedActivity}
        editActivity={editActivity}
        setEditActivity={setEditActivity}
        createReport={createReport}
        setCreateReport={setCreateReport}
        setLoading={setLoadingActivities}
      />

      <div className='popup_header'>
        <div className='go_back' onClick={handleClosePopup}><PiCaretLeftThin /></div>
        <div className='header__buttons'>
          {
            edit | changesMadeCustomDetails ?
              <>
                <button className='red-button-header' onClick={handleCancel} ref={saveButtonRef}>CANCEL</button>
                <button className='green-button-header' onClick={handleSave} ref={saveButtonRef}>
                  {
                    loadingSaveButton ?
                      <CircularProgress color="inherit" size={"1rem"} /> :
                      <>SAVE</>
                  }
                </button>
              </>
              :
              <>
                {
                  selectedMenu == "CONTACT" &&
                  <div className='header__button' onClick={() => setEdit(true)}><PiPencilLineThin /></div>
                }

                {
                  selectedMenu == "AVAILABILITY" &&
                  <div className='header__button' onClick={() => setFiltersPopupIsActive(!filtersPopupIsActive)}><CiFilter /></div>
                }

                {
                  selectedMenu == "ACTIVITIES" &&
                  <div className='header__button' onClick={() => setCreateActivityPopupIsActive(true)}><PiPlusThin /></div>
                }

                {
                  !createActivityPopupIsActive &&
                  <div className='header__button side-menu' onClick={() => setSideMenuOpen(!sideMenuOpen)}><HamburgerMenu isOpen={sideMenuOpen} setIsOpen={setSideMenuOpen} /></div>
                }
              </>

          }
        </div>
      </div>
      <div className='popup_body'>
        {
          selectedMenu == "CONTACT" &&
          <form>

            <h1>Contact Details</h1>
            <input className={`input_text--mobile ${edit ? "" : "disabled"}`} type='text' placeholder='Business name' name='business_name' value={customer.business_name} onChange={handleOnchangeInput} />
            <input className={`input_text--mobile ${edit ? "" : "disabled"}`} type='email' placeholder='Email' name='email' value={customer.email} onChange={handleOnchangeInput} />
            <input className={`input_text--mobile ${edit ? "" : "disabled"}`} type='tel' placeholder='Telephone number' name='tel_number' value={customer.tel_number} onChange={handleOnchangeInput} />
            <div className='input_field--combo-5050'>
              <input className={`input_text--mobile ${edit ? "" : "disabled"}`} type='text' placeholder='First name' name='first_name' value={customer.first_name} onChange={handleOnchangeInput} />
              <input className={`input_text--mobile ${edit ? "" : "disabled"}`} type='text' placeholder='Last name' name='last_name' value={customer.last_name} onChange={handleOnchangeInput} />
            </div>
            <div className={`form_dropdown--mobile ${edit ? "" : "disabled"}`} name='type' onClick={(e) => handleOnClickPopup(e, "type")}>
              {
                customer.type ?
                  <p>{customer.type}</p> :
                  <p className='placeholder'>Type</p>
              }
              <MdOutlineArrowDropDown />
            </div>
            <div className={`form_dropdown--mobile ${edit ? "" : "disabled"}`} name='sub_type' onClick={(e) => handleOnClickPopup(e, "sub_type")}>
              {
                customer.sub_type ?
                  <p>{customer.sub_type}</p> :
                  <p className='placeholder'>Sub type</p>
              }
              <MdOutlineArrowDropDown />
            </div>
            {
              customer.is_supplier ?
                <input className={`input_text--mobile ${edit ? "" : "disabled"}`} type='email' placeholder='Sales email' name='sales_email' value={customer.sales_email} onChange={handleOnchangeInput} /> :
                null
            }
            <textarea className={`input_text--mobile textarea ${edit ? "" : "disabled"}`} type='text' placeholder='Bio' name='bio' value={customer.bio} onChange={handleOnchangeInput} />

            <h1>Invoicing</h1>
            <div className='input_wth_button--wrapper'>
              <input className={`input_text--mobile ${edit ? "wdth75" : "disabled"} `} type='text' placeholder='Company name' name='company_name' value={customer.company_name} onChange={handleOnchangeInput} />
              {
                edit &&
                <button className='form_option_button' onClick={(e) => handleDuplicate(e, "company_name")}>Duplicate</button>
              }
            </div>
            <div className='input_wth_button--wrapper'>
              <input className={`input_text--mobile ${edit ? "wdth75" : "disabled"} `} type='email' placeholder='Invoice email' name='invoice_email' value={customer.invoice_email} onChange={handleOnchangeInput} />
              {
                edit &&
                <button className='form_option_button' onClick={(e) => handleDuplicate(e, "invoice_email")}>Duplicate</button>
              }
            </div>
            <input className={`input_text--mobile ${edit ? "" : "disabled"}`} type='text' placeholder='VAT number' name='VAT_number' value={customer.VAT_number} onChange={handleOnchangeInput} />
            <div className='input_field--combo-7030--mobile'>
              <input className={`input_text--mobile ${edit ? "" : "disabled"}`} type='text' placeholder='Street' name='invoice_address_street' value={customer.invoice_address_street} onChange={handleOnchangeInput} />
              <input className={`input_text--mobile ${edit ? "" : "disabled"}`} type='text' placeholder='Nr' name='invoice_address_nr' value={customer.invoice_address_nr} onChange={handleOnchangeInput} />
            </div>
            <div className='input_field--combo-3070--mobile'>
              <input className={`input_text--mobile ${edit ? "" : "disabled"}`} type='text' placeholder='Postal code' name='invoice_address_pc' value={customer.invoice_address_pc} onChange={handleOnchangeInput} />
              <input className={`input_text--mobile ${edit ? "" : "disabled"}`} type='text' placeholder='City' name='invoice_address_place' value={customer.invoice_address_place} onChange={handleOnchangeInput} />
            </div>
            <input className={`input_text--mobile ${edit ? "" : "disabled"}`} type='text' placeholder='Country' name='invoice_address_country' value={customer.invoice_address_country} onChange={handleOnchangeInput} />

            <h1>Delivery</h1>
            <div className='input_field--combo-7030--mobile'>
              <input className={`input_text--mobile ${edit ? "" : "disabled"}`} type='text' placeholder='Street' name='delivery_address_street' value={customer.delivery_address_street} onChange={handleOnchangeInput} />
              <input className={`input_text--mobile ${edit ? "" : "disabled"}`} type='text' placeholder='Nr' name='delivery_address_nr' value={customer.delivery_address_nr} onChange={handleOnchangeInput} />
            </div>
            <div className='input_field--combo-3070--mobile'>
              <input className={`input_text--mobile ${edit ? "" : "disabled"}`} type='text' placeholder='Postal code' name='delivery_address_pc' value={customer.delivery_address_pc} onChange={handleOnchangeInput} />
              <input className={`input_text--mobile ${edit ? "" : "disabled"}`} type='text' placeholder='City' name='delivery_address_place' value={customer.delivery_address_place} onChange={handleOnchangeInput} />
            </div>
            <input className={`input_text--mobile ${edit ? "" : "disabled"}`} type='text' placeholder='Country' name='delivery_address_country' value={customer.delivery_address_country} onChange={handleOnchangeInput} />
            {
              edit &&
              <button className='form_option_button' onClick={(e) => handleDuplicate(e, "address")}>Duplicate</button>
            }

            <div className="other_options">
              <CheckBox
                id="customers__is_supplier"
                text="Is supplier"
                state={customer.is_supplier}
                setData={handleIsSupplier}
                style="mobile"
                textPosition="left"
                disabled={!edit}
              />
            </div>

          </form>
        }

        {
          selectedMenu == "DETAILS" &&
          <div className='popup_body__content_wrapper'>
            <h1>Contact rhythm</h1>
            <div className={`form_dropdown--mobile`} name='contact_rhythm' onClick={(e) => handleOnClickPopup(e, "contact_rhythm")}>
              {
                contactRhythm ?
                  <p>{contactRhythm.rhythm}</p> :
                  <p className='placeholder'>Contact rhythm</p>
              }
              <MdOutlineArrowDropDown />
            </div>

            <h1>Custom details</h1>
            <div className="classification_section__content_container">
              {

                customDetails.length != 0 ?
                  <div className="custom_details__wrapper">
                    {
                      renderCustomerDetail(customDetails)
                    }
                  </div> : null
              }
            </div>
          </div>
        }

        {
          selectedMenu == "AVAILABILITY" &&
          <>
            {
              <div className='popup_body__content_wrapper'>
                <h1>Available products</h1>
                <SearchBarList
                  filters={availabilityFilters}
                  updateFilters={setAvailabilityFilters}
                  stateKey={"searchWord"}
                  style={'mobile'}
                />
                <div className="availability_section__content_container">
                  {
                    loadingProductAvailability ?
                      <div className='loading_circle_wrapper'><CircularProgress color="inherit" size="1.5rem" /></div> :
                      availabilityState?.productsAvailability
                        .map((product, index) => {
                          return (
                            <div className="product" key={index} data-product-id={product.id} data-product-type={product.is_POSM ? 'material' : 'product'}>
                              <p>{product.name} {product.volume}</p>
                              <CheckBoxStatic
                                labelDisabled={true}
                                state={product.availability}
                                onClick={onClickCheckAvailability}
                                style={"mobile"}
                                widthHeight={"30px"}
                              />
                            </div>
                          )
                        })
                  }
                  {
                    availabilityState.productsHasMore ?
                      <button className="load_more_button" onClick={() => handleOnClickLoadMore("products")}>{availabilityState.loadingMoreMaterials ? <CircularProgress color="inherit" size="1rem" /> : "Click to load more..."}</button>
                      : null
                  }
                </div>
                <h1>Available POSM</h1>
                <div className="availability_section__content_container">
                  {
                    loadingProductAvailability ?
                      <div className='loading_circle_wrapper'><CircularProgress color="inherit" size="1.5rem" /></div> :

                      availabilityState.materialsAvailability
                        .filter(product => product.is_POSM)
                        .map((product, index) => {
                          return (
                            <div className="product" key={index} data-product-id={product.id} data-product-type={product.is_POSM ? 'material' : 'product'}>
                              <p>{product.name} {product.volume}</p>
                              <CheckBoxStatic
                                labelDisabled={true}
                                state={product.availability}
                                onClick={onClickCheckAvailability}
                                style={"mobile"}
                                widthHeight={"30px"}
                              />
                            </div>
                          )
                        })
                  }
                  {
                    availabilityState.materialsHasMore ?
                      <button className="load_more_button" onClick={() => handleOnClickLoadMore("materials")}>{availabilityState.loadingMoreMaterials ? <CircularProgress color="inherit" size="1rem" /> : "Load more..."}</button>
                      : null
                  }
                </div>
              </div>
            }
          </>
        }

        {
          selectedMenu == "SUPPLIERS" &&
          <div className='popup_body__content_wrapper'>
            {
              ["wholesaler"].includes(userInfo.app_version) && (
                <div className="suppliers_container">
                  <h1>Customer Suppliers</h1>
                  <div className="add-supplier">
                    <PopupDropdown
                      searchBar={true}
                      options={suppliers}
                      value={newSupplier}
                      onChange={setNewSupplier}
                      title="Select Supplier"
                      inputBoxHeight={"100%"}
                      isMobile={true}
                    />
                    <input
                      className="input_text--mobile"
                      placeholder="Alias (optional)"
                      value={newAlias}
                      onChange={(e) => setNewAlias(e.target.value)}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          handleAddSupplier(e)
                        }
                      }}
                    />
                    <button className="add--btn mobile" onClick={(e) => handleAddSupplier(e)} id="add-supplier-btn">
                      <AddIcon />
                    </button>
                  </div>
                  <div className="suppliers_list">
                    {
                      loadingCustomerSupplier ?
                        <div className="supplier_item">
                          <div className={`supplier_item__name skeleton`}></div>
                          <div className={`supplier_item__alias skeleton`}></div>
                          <button className={`delete-btn hide`} onClick={() => null}>
                            <DeleteIcon />
                          </button>
                        </div>
                        :
                        <>
                          {
                            customerSuppliers.map(supplier => (
                              <div className="supplier_item" key={supplier.id}>
                                <div className={`supplier_item__name`}>{supplier.supplier_id.business_name}</div>
                                <div className={`supplier_item__alias`}>{supplier.alias}</div>
                                <button className={`delete-btn`} onClick={(e) => handleDeleteSupplier(e, supplier.supplier_id.id)}>
                                  <DeleteIcon />
                                </button>
                              </div>
                            ))
                          }
                        </>
                    }
                  </div>
                </div>
              )
            }

            {
              ["brandowner"].includes(userInfo.app_version) && (
                <>
                  <h1>Products</h1>
                  <div className="input_field__wrapper mobile special">
                    <div className="input_field__label mobile">ALL PRODUCTS</div>
                    <div className="input_field__input_box--text static mobile special" onClick={(e) => handleOnclickSelectSupplier(e, {}, "PRODUCTS")}>Select supplier <br /> for all products</div>
                  </div>
                  {
                    myProductSuppliers
                      .filter(product => product.product_category != "POSM")
                      .map((productSupplier, index) => {
                        return (
                          <div className="input_field__wrapper mobile" key={index}>
                            <div className="input_field__label mobile">{productSupplier.product_name} {productSupplier.product_volume}</div>
                            <div className="input_field__input_box--text static mobile" onClick={(e) => handleOnclickSelectSupplier(e, productSupplier)}>{productSupplier.supplier_name != "" ? productSupplier.supplier_name : `Select a supplier for ${productSupplier.product_name} ${productSupplier.product_volume}`}</div>
                          </div>
                        )
                      })

                  }

                  <h1>POSM</h1>
                  <div className="input_field__wrapper mobile special">
                    <div className="input_field__label mobile">ALL POSM</div>
                    <div className="input_field__input_box--text static mobile special" onClick={(e) => handleOnclickSelectSupplier(e, {}, "POSM")}>Select supplier <br /> for all POSM</div>
                  </div>
                  {
                    myProductSuppliers
                      .filter(product => product.product_category == "POSM")
                      .map((productSupplier, index) => {
                        return (
                          <div className="input_field__wrapper mobile" key={index}>
                            <div className="input_field__label mobile">{productSupplier.product_name} {productSupplier.product_volume}</div>
                            <div className="input_field__input_box--text static mobile" onClick={(e) => handleOnclickSelectSupplier(e, productSupplier)}>{productSupplier.supplier_name != "" ? productSupplier.supplier_name : `Select a supplier for ${productSupplier.product_name} ${productSupplier.product_volume}`}</div>
                          </div>
                        )
                      })
                  }
                </>
              )
            }
          </div>
        }

        {
          selectedMenu == "CONTENT" &&
          <div className='popup_body__content_wrapper'>
            <h1>Photos</h1>
            <CustomerContentGrid
              customerID={selectedCustomer.id}
              content={customerContentImages}
              fetchCustomerContent={fetchCustomerContent}
              isMobile={true}
              imageOnly={true}
            />
            <h1>Files</h1>
            <CustomerContentGrid
              customerID={selectedCustomer.id}
              content={customerContentFiles}
              fetchCustomerContent={fetchCustomerContent}
              isMobile={true}
              imageOnly={false}
            />
          </div>
        }

        {
          selectedMenu == "ACTIVITIES" &&
          <div className='popup_body__content_wrapper' id='activities-of-customer'>
            {
              loadingActivities ?
                <div className='circular_loading_spinner__wrapper'><CircularProgress color='inherit' size="2rem" /></div>
                :
                <div className='table no-scrollbar'>
                  {
                    activities.map((activity, index) => {

                      let isOverdue = false
                      if (new Date(activity.planned_date) < now & !activity.done) {
                        isOverdue = true
                      }

                      let owner = ""
                      if (activity.sales_rep_id.last_name) {
                        owner = `${activity.sales_rep_id?.first_name} ${activity.sales_rep_id.last_name.charAt(0)}.`
                      } else {
                        owner = `${activity.sales_rep_id?.first_name}`
                      }

                      return (
                        <div className='table__row_wrapper' key={index} >
                          <div className={`activity_container--mobile ${isOverdue ? "overdue" : ""} ${activity.done ? "done" : ""}`}>
                            <div className='activity_data_field check-box'>
                              <CheckBox
                                index={activity.id}
                                state={activity.done}
                                width={"30px"}
                                height={"30px"}
                                margin={"0"}
                                setData={handlePreCheckDone}
                                style={"mobile"}
                                type={"controlled"}
                              />
                            </div>
                            <div className='activity_data_field title'>{activity.title}</div>
                            {/* <div className='activity_data_field picto'>{activity_types_svgs[activity.type].picto}</div> */}
                            <div className='activity_data_field owner'>{owner}</div>
                            <div className='activity_data_field date'>{formatDateWithTime(activity.planned_date, true)}</div>
                          </div>
                          <div className='table__row--field--arrow' onClick={(e) => handleEditActivity(e, activity.id)}><PiCaretRightThin /></div>
                        </div>
                      )
                    })
                  }
                </div>
            }

          </div>
        }
      </div>

    </div>
  )
}

export default PopupwindowCustomerInfoMobile

PopupwindowCustomerInfoMobile.defaultProps = {
  redirectToCustomerInfo: false
}
