import React, { useState, useEffect, useRef } from "react"
import { useSelector, useDispatch } from "react-redux"
import httpClient from "../httpClient"
import "./CustomersPage.css"
import Header from "../components/Header"
import MenuBar from "../components/MenuBar"
import DropDownFilter from "../components/DropDownFilter"
import LoadingSpinner from "../components/placeholders/LoadingSpinner"
import { Link, useLocation, useNavigate } from "react-router-dom"
import Slider from "../components/Slider"
import TableCheckmarkHeader from '../components/TableCheckmarkHeader'
import TableCheckmarkRow from '../components/TableCheckmarkRow'
import DropdownButton from "../components/DropdownButton"
import { toast } from "react-toastify"
import ExitToAppIcon from '@mui/icons-material/ExitToApp'
import LoadingSpinnerOverlay from "../components/placeholders/LoadingSpinnerOverlay"
import { exportToExcel } from "../components/helpers/Exports"
import SearchBarFilter from "../components/SearchBarFilter.js"
import CircularProgress from '@mui/material/CircularProgress'
import {
  updateFilters,
  updateCustomerList,
  resetStateCustomerPage,
  updateTotalPagesCustomers,
  updateArrayOfCitiesCustomers,
  updateMapView,
  updateMapCustomers,
  updateMapTotalRecords,
  updateMapHasMore,
  updateMapPage
} from "../store/customerPage"
import Pagination from "../components/Pagination.js"
import CustomerMap from "../components/CustomerMap.js"
import { LiaMapMarkedAltSolid } from "react-icons/lia"
import PopupWindowCreateNewCustomer from "../components/PopupWindowCreateNewCustomer.js"
import { IoFilterSharp } from "react-icons/io5"
import PopOutWindowAdvancedFilters from "../components/PopOutWindowAdvancedFilters.js"
import PopOutWindowExportCustomers from "../components/PopOutWindowExportCustomers.js"
import { MdRefresh } from "react-icons/md"
import { IoMdOptions } from "react-icons/io"
import { selectFilteredMyTeam } from "../store/user"

function CustomersPage() {
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(false)
  const [loadingMap, setLoadingMap] = useState(false)
  const [selectedRows, setSelectedRows] = useState([])
  const [limit, setLimit] = useState(20)
  const [typesDropdownIsOpen, setTypesDropdownIsOpen] = useState(false)
  const [subTypeDropdownIsOpen, setsubTypeDropdownIsOpen] = useState(false)
  const [selectedType, setSelectedType] = useState("")
  const [loadingExport, setLoadingExport] = useState(false)
  const [createNewCustomer, setCreateNewCustomer] = useState(false)
  const [advancedFiltersIsOpen, setAdvancedFiltersIsOpen] = useState(false)
  const [exportPopupIsOpen, setExportPopupIsOpen] = useState(false)

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const userInfo = useSelector(state => state.user.userInfo)
  const filters = useSelector(state => state.customerPage.filters)
  const customers = useSelector(state => state.customerPage.customerList)
  const customerTypesArray = useSelector(state => state.user.customer_types)
  const totalPages = useSelector(state => state.customerPage.totalPagesCustomers)
  const arrayOfCities = useSelector(state => state.customerPage.arrayOfCitiesCustomers)
  const mapViewObj = useSelector(state => state.customerPage.mapView)
  const myTeam = useSelector(selectFilteredMyTeam)
  const mapView = mapViewObj.state
  const mapCustomers = useSelector(state => state.customerPage.mapCustomers)
  const mapTotalRecords = useSelector(state => state.customerPage.mapTotalRecords)
  const mapHasMore = useSelector(state => state.customerPage.mapHasMore)
  const mapPage = useSelector(state => state.customerPage.mapPage)
  const arrayOfTypes = customerTypesArray && Object.keys(customerTypesArray)
  const arrayOfSubTypes = customerTypesArray && customerTypesArray[filters.typeFilter] || []

  const previousFilters = useRef(filters)
  const previousMapviewState = useRef(mapView)
  const dropdownRefTypes = useRef(null)
  const parentDivRef = useRef(null)
  const dropdownRefSubTypes = useRef(null)

  const fetchCustomers = async (loadMore = false) => {

    if (mapView) {
      setLoadingMap(true)
    } else {
      setLoading(true)
    }

    // Determine the current map page
    const currentMapPage = loadMore ? mapPage : 1

    const requestData = {
      listType: filters.customersListType,
      filters: {
        city: filters.cityFilter,
        postal_code: filters.postalCodeFilter,
        type: filters.typeFilter,
        sub_type: filters.subTypeFilter,
        search_word: filters.searchWord,
        b2c: filters.filterB2C,
        owner: filters.owner,
        mapBounds: filters.mapBounds,
        advancedFilters: filters.advancedFilters
      },
      sort: filters.sortFilter,
      sortAscending: filters.sortAscending,
      mapView: mapView,
      mapPage: currentMapPage
    }

    try {
      const responseCustomers = await httpClient.post(
        `${process.env.REACT_APP_API_URL}/api/users/get?page=${filters.page}&limit=${limit}`,
        requestData
      )

      if (mapView) {
        const { customers: newCustomers, totalRecords, hasMore, currentPage } = responseCustomers.data

        if (loadMore) {
          dispatch(updateMapCustomers([...mapCustomers, ...newCustomers]))
        } else {
          dispatch(updateMapCustomers(newCustomers))
        }

        dispatch(updateMapTotalRecords(totalRecords))
        dispatch(updateMapHasMore(hasMore))
        dispatch(updateMapPage(currentPage))
        setLoadingMap(false)
      } else {
        dispatch(updateCustomerList(responseCustomers.data[0]))
        dispatch(updateTotalPagesCustomers(responseCustomers.data[1]))
        dispatch(updateArrayOfCitiesCustomers(responseCustomers.data[2]))
        setLoading(false)
      }
    } catch (error) {
      console.error('Error fetching customers:', error)
      setLoadingMap(false)
      setLoading(false)
    }
  }

  useEffect(() => {

    if (customers.length == 0) {
      fetchCustomers()
        .catch(err => {
          console.log(err)
        })
    }

    const header = document.getElementById("header-title")
    header.classList.add("hide")
  }, [])

  useEffect(() => {
    const previousFiltersDict = previousFilters.current

    // Compare the current filters with the previous filters
    if (JSON.stringify(previousFiltersDict) !== JSON.stringify(filters)) {
      if (previousFiltersDict.page == filters.page) {
        // if page did not change, page has to be reset to 1 (because a filter changed)
        dispatch(updateFilters({ ...filters, page: 1 }))
      }

      fetchCustomers()
        .catch(err => {
          console.log(err)
        })

      // Update previous filters to current filters
      previousFilters.current = filters
    }
  }, [filters])

  useEffect(() => {
    if (previousMapviewState.current != mapView) {
      fetchCustomers()
        .catch(err => {
          console.log(err)
        })

      // Update previous mapViewState to current filters
      previousMapviewState.current = mapView
    }
  }, [mapView])

  const handleLoadMore = () => {
    dispatch(updateMapPage(mapPage + 1))
  }

  useEffect(() => {
    if (mapView && mapPage > 1) {
      fetchCustomers(true)
    }
  }, [mapPage])

  useEffect(() => {
    let dropdownElement = null

    if (typesDropdownIsOpen) {
      dropdownElement = dropdownRefTypes.current
    } else if (subTypeDropdownIsOpen) {
      dropdownElement = dropdownRefSubTypes.current
    }

    const parentDivElement = parentDivRef.current

    if (dropdownElement) {
      const dropdownRect = dropdownElement.getBoundingClientRect()
      const parentDivRect = parentDivElement.getBoundingClientRect()

      const { top: dropdownTop, height: dropdownHeight } = dropdownRect
      const { top: parentDivTop, height: parentDivHeight } = parentDivRect

      const dropdownBottom = dropdownTop + dropdownHeight
      const parentDivBottom = parentDivTop + parentDivHeight

      if (dropdownBottom > parentDivBottom) {
        // Calculate the amount to scroll by
        const scrollAmount = dropdownBottom - parentDivBottom
        // Scroll the parent div with smooth animation
        parentDivElement.scrollBy({
          top: scrollAmount,
          behavior: 'smooth',
        })
      }
    }
  }, [typesDropdownIsOpen, subTypeDropdownIsOpen])

  const handleCustomerClick = (customerId) => {
    navigate(`/customer/${customerId}`)
  }

  const handleCreateNewCustomer = () => {
    // setCreateNewCustomer(true)
    navigate("/customer/new")
  }

  const handleOnClickItem = (event, data) => {

    const filter = event.currentTarget.textContent

    const updatedFilters = { ...filters }

    switch (event.target.parentElement.id) {
      case "customers__dropdownList--sub-type":
        updatedFilters["subTypeFilter"] = filter
        document.getElementById("customers__dropdownList--sub-type").classList.remove("active")
        break
      case "customers__dropdownList--type":
        updatedFilters["typeFilter"] = filter
        document.getElementById("customers__dropdownList--type").classList.remove("active")
        break
      case "customers__dropdownList--c":
        updatedFilters["cityFilter"] = filter
        document.getElementById("customers__dropdownList--c").classList.remove("active")
        break
      case "customers__dropdownList--customer-owner":
        updatedFilters["owner"] = data
        document.getElementById("customers__dropdownList--c").classList.remove("active")
        break
      case "customers__dropdownList--customer-status":
        if (filter == "All") {
          updatedFilters["customersListType"] = filter
        } else if (filter == "Have my products") {
          updatedFilters["customersListType"] = filter
        } else if (filter == "Don't have my products (yet 😏)") {
          updatedFilters["customersListType"] = "Don't have my products"
        }

        document.getElementById("customers__dropdownList--customer-status").classList.remove("active")
        break
    }

    updatedFilters["page"] = 1
    dispatch(updateFilters(updatedFilters))
  }

  const handleSetSortFilter = (event) => {

    const innerHTML = event.currentTarget.innerHTML
    const updatedFilters = { ...filters }

    switch (innerHTML) {
      case "Business name":
        if (filters.sortFilter == "business_name") {
          updatedFilters["sortAscending"] = !filters.sortAscending
        } else {
          updatedFilters["sortFilter"] = "business_name"
          updatedFilters["sortAscending"] = true
        }
        break
      case "Contact name":
        if (filters.sortFilter == "first_name") {
          updatedFilters["sortAscending"] = !filters.sortAscending
        } else {
          updatedFilters["sortFilter"] = "first_name"
          updatedFilters["sortAscending"] = true
        }
        break
      case "Tel":
        if (filters.sortFilter == "tel_number") {
          updatedFilters["sortAscending"] = !filters.sortAscending
        } else {
          updatedFilters["sortFilter"] = "tel_number"
          updatedFilters["sortAscending"] = true
        }
        break
      case "City":
        if (filters.sortFilter == "delivery_address_place") {
          updatedFilters["sortAscending"] = !filters.sortAscending
        } else {
          updatedFilters["sortFilter"] = "delivery_address_place"
          updatedFilters["sortAscending"] = true
        }
        break
      case "Postal Code":
        if (filters.sortFilter == "delivery_address_pc") {
          updatedFilters["sortAscending"] = !filters.sortAscending
        } else {
          updatedFilters["sortFilter"] = "delivery_address_pc"
          updatedFilters["sortAscending"] = true
        }
        break
      case "Email":
        if (filters.sortFilter == "email") {
          updatedFilters["sortAscending"] = !filters.sortAscending
        } else {
          updatedFilters["sortFilter"] = "email"
          updatedFilters["sortAscending"] = true
        }
        break
      case "Type":
        if (filters.sortFilter == "type") {
          updatedFilters["sortAscending"] = !filters.sortAscending
        } else {
          updatedFilters["sortFilter"] = "type"
          updatedFilters["sortAscending"] = true
        }
        break
      case "Sub type":
        if (filters.sortFilter == "sub_type") {
          updatedFilters["sortAscending"] = !filters.sortAscending
        } else {
          updatedFilters["sortFilter"] = "sub_type"
          updatedFilters["sortAscending"] = true
        }
        break
    }
    updatedFilters["page"] = 1
    dispatch(updateFilters(updatedFilters))
  }

  const addToSalesPipe = async (value) => {

    let data = {
      "type": value,
      "customerIDs": []
    }

    selectedRows.forEach(row => {
      const customerID = row.getAttribute("data-customer-id")

      data["customerIDs"].push(parseInt(customerID))
    })

    const response = await toast.promise(
      httpClient.post(process.env.REACT_APP_API_URL + "/api/sales-pipe/add", data),
      {
        pending: "Adding to SALES PIPEs...",
        success: `Done!`,
        error: "Something went wrong"
      }
    )
    console.log(response.data)

    setSelectedRows([])
  }

  const handleExport = () => {
    if (userInfo.api_partners.includes("MailChimp")) {
      setExportPopupIsOpen(true)
    } else {
      handleExportCustomers("excel")
    }
  }

  const handleExportCustomers = async (type = "excel") => {
    setLoadingExport(true)

    const fileName = `Customers_export_STOQUP`
    const exportData = []

    const requestData = {
      listType: filters.customersListType,
      filters: {
        city: filters.cityFilter,
        postal_code: filters.postalCodeFilter,
        type: filters.typeFilter,
        sub_type: filters.subTypeFilter,
        search_word: filters.searchWord,
        b2c: filters.filterB2C,
        owner: filters.owner,
        mapBounds: filters.mapBounds,
        advancedFilters: filters.advancedFilters
      },
      sort: filters.sortFilter,
      fullFetch: true,
      sortAscending: filters.sortAscending,
      mapView: mapView,
    }

    const response = await httpClient.post(
      `${process.env.REACT_APP_API_URL}/api/users/get`,
      requestData
    )

    response.data.forEach(customer => {
      const customerObj = {
        'id': customer.id,
        'company_name': customer.company_name,
        'business_name': customer.business_name,
        'invoice_email': customer.invoice_email,
        'email': customer.email,
        'VAT_number': customer.VAT_number,
        'first_name': customer.first_name,
        'last_name': customer.last_name,
        'tel_number': customer.tel_number,
        'delivery_address_street': customer.delivery_address_street,
        'delivery_address_nr': customer.delivery_address_nr,
        'delivery_address_pc': customer.delivery_address_pc,
        'delivery_address_place': customer.delivery_address_place,
        'delivery_address_country': customer.delivery_address_country,
        'invoice_address_street': customer.invoice_address_street,
        'invoice_address_nr': customer.invoice_address_nr,
        'invoice_address_pc': customer.invoice_address_pc,
        'invoice_address_place': customer.invoice_address_place,
        'invoice_address_country': customer.invoice_address_country,
        'type': customer.type,
        'sub_type': customer.sub_type,
        'bio': customer.bio,
      }
      exportData.push(customerObj)
    })

    if (type == "excel") {
      exportToExcel(exportData, fileName)
    } else if (type == "mailchimp") {
      setLoadingExport(false)
      return exportData
    }

    setLoadingExport(false)
  }

  const handleDeleteCustomers = async () => {
    const customerIDs = selectedRows.map(row =>
      parseInt(row.getAttribute("data-customer-id"))
    )

    try {
      await toast.promise(
        httpClient.post(`${process.env.REACT_APP_API_URL}/api/customers/delete`, {
          customerIDs: customerIDs
        }),
        {
          pending: "Deleting customers...",
          success: "Customers deleted successfully!",
          error: "Failed to delete customers"
        }
      );

      // Refresh the customer list
      fetchCustomers()
      // Clear selection
      setSelectedRows([])

    } catch (error) {
      console.error('Error deleting customers:', error)
    }
  }

  return (
    <div className="template">

      {/* {error ? window.location.href = "/" : ""} */}

      <PopupWindowCreateNewCustomer
        isActive={createNewCustomer}
        setIsActive={setCreateNewCustomer}
        fetchCustomers={fetchCustomers}
        redirectToCustomerPage={true}
      />
      
      <PopOutWindowAdvancedFilters
        popupID="advanced-filters-popup"
        isActive={advancedFiltersIsOpen}
        setIsActive={setAdvancedFiltersIsOpen}
        width={"33%"}
      />

      <PopOutWindowExportCustomers
        isActive={exportPopupIsOpen}
        setIsActive={setExportPopupIsOpen}
        width={"33%"}
        handleExportCustomers={handleExportCustomers}
      />

      <Header>
        <div className="header__options">
          {
            (["manager", "admin"].includes(userInfo.type)) & selectedRows.length == 0 ?
              <>
                <button className="options-button-header medium_small" onClick={handleExport}>{
                  loadingExport ?
                    <LoadingSpinnerOverlay /> :
                    <>Export <ExitToAppIcon /></>
                }
                </button>
              </>
              : ""
          }
          <button className="options-button-header medium_small" onClick={() => setAdvancedFiltersIsOpen(!advancedFiltersIsOpen)}>More filters <IoFilterSharp /></button>
          <button className={`options-button-header medium_small ${mapView ? "selected" : ""}`} onClick={() => dispatch(updateMapView({ ...mapViewObj, state: !mapView }))}>Map view<LiaMapMarkedAltSolid /> </button>
          <button className="options-button-header medium_small" onClick={fetchCustomers}>Refresh <MdRefresh /></button>
        </div>

        {
          selectedRows.length == 0 
          ?
          <div className="header__filters" id="customer__table-btns">
            <SearchBarFilter
              id="search-bar--customers"
              filters={filters}
              updateFilters={updateFilters}
              stateKey="searchWord"
              stateType="redux"
            />
            <DropDownFilter
              idList="customers__dropdownList--c"
              placeholderText="City"
              listArray={arrayOfCities || []}
              onClickItem={handleOnClickItem}
              value={filters.cityFilter}
              filter={"cityFilter"}
              setValue={updateFilters}
              widthSize={"medium"}
              reduxState={filters}
            />
            <DropDownFilter
              idList="customers__dropdownList--type"
              placeholderText="Type"
              listArray={arrayOfTypes}
              onClickItem={handleOnClickItem}
              value={filters.typeFilter}
              filter={"typeFilter"}
              setValue={updateFilters}
              widthSize={"medium"}
              reduxState={filters}
            />
            <DropDownFilter
              idList="customers__dropdownList--sub-type"
              placeholderText="Sub type"
              listArray={arrayOfSubTypes}
              onClickItem={handleOnClickItem}
              value={filters.subTypeFilter}
              filter={"subTypeFilter"}
              setValue={updateFilters}
              widthSize={"medium"}
              reduxState={filters}
            />
            <DropDownFilter
              idList="customers__dropdownList--customer-status"
              placeholderText="Status"
              listArray={["All", "Have my products", "Don't have my products (yet 😏)"]}
              onClickItem={handleOnClickItem}
              value={filters.customersListType}
              filter={"customersListType"}
              setValue={updateFilters}
              widthSize={"medium"}
              reduxState={filters}
            />
            {
              userInfo.type != "admin" ?
                <DropDownFilter
                  idList="customers__dropdownList--customer-owner"
                  placeholderText="Owner"
                  listArray={myTeam}
                  onClickItem={handleOnClickItem}
                  value={filters.owner}
                  filter={"owner"}
                  setValue={updateFilters}
                  widthSize={"medium"}
                  reduxState={filters}
                /> : null
            }
          </div>
          :
          <div className={`header__options--right-template ${selectedRows.length == 0 ? "hide" : ""}`} id="header__options--users-admin">
          <button
            className="options-button-header--red"
            onClick={handleDeleteCustomers}
          >
            Delete
          </button>
          <DropdownButton
            id={"customers-1"}
            title="Add to SALES PIPE"
            valuesArray={["Prospects", "Visited", "Undecided", "Clients", "Lost", "Ambassadors"]}
            onClickFn={addToSalesPipe}
          />
        </div>
        }

        {/* TODO: Duplicates page */}
        {/* {
          selectedRows.length == 0 &&
          <div className="header__options--top-right">
            <div className="option_button" onClick={() => navigate("/customer/duplicates")}>
              <IoMdOptions/>
            </div>
          </div>
        } */}
      </Header>
      <MenuBar />

      <div className="template__body">

        {
          !mapView && <button className="floating__button--bottom-right" id="add--btn" onClick={handleCreateNewCustomer} />
        }

        <div className="body__customers" id="body-customers">

          <div className="body__section big" id="all-customers-table">
            {
              loading ?
                <LoadingSpinner /> :
                mapView ?
                  <CustomerMap
                    customers={mapCustomers}
                    loading={loadingMap}
                    totalRecords={mapTotalRecords}
                    hasMore={mapHasMore}
                    onLoadMore={handleLoadMore}
                    setMapPage={updateMapPage}
                  /> :
                  <div className="table" id="customer--table">
                    <div className="table__header-9">
                      <TableCheckmarkHeader
                        id="table-checkmark-header-customer"
                        selectedRows={selectedRows}
                        setSelectedRows={setSelectedRows}
                      />
                      <div className="table__header--field" onClick={handleSetSortFilter}>Business name</div>
                      <div className="table__header--field" onClick={handleSetSortFilter}>Contact name</div>
                      <div className="table__header--field" onClick={handleSetSortFilter}>Tel</div>
                      <div className="table__header--field" onClick={handleSetSortFilter}>City</div>
                      <div className="table__header--field" onClick={handleSetSortFilter}>Postal Code</div>
                      <div className="table__header--field" onClick={handleSetSortFilter}>Email</div>
                      <div className="table__header--field" onClick={handleSetSortFilter}>Type</div>
                      <div className="table__header--field" onClick={handleSetSortFilter}>Sub type</div>
                    </div>
                    <div className="table__rows_container">
                      {
                        customers
                          .map((customer, index) => {
                            return (
                              <div className="table__row-9" id='checkmark-row' data-customer-id={customer.id} key={index} onClick={() => handleCustomerClick(customer.user_id.id)} >
                                <TableCheckmarkRow
                                  index={index}
                                  id={`customers-${index}`}
                                  selectedRows={selectedRows}
                                  setSelectedRows={setSelectedRows}
                                />
                                <div className="table__row--field">{customer.business_name}</div>
                                <div className="table__row--field">{customer.first_name} {customer.last_name}</div>
                                <div className="table__row--field">{customer.tel_number}</div>
                                <div className="table__row--field">{customer.delivery_address_place}</div>
                                <div className="table__row--field">{customer.delivery_address_pc}</div>
                                <div className="table__row--field">{customer.email}</div>
                                <div className="table__row--field">{customer.type}</div>
                                <div className="table__row--field">{customer.sub_type}</div>
                              </div>
                            )
                          })
                      }
                    </div>
                    <Pagination
                      id="pagination-customers-page-list"
                      stateType="redux"
                      filters={filters}
                      page={filters.page}
                      setPage={updateFilters}
                      totalPages={totalPages}
                    />
                  </div>

            }
          </div>
        </div>

      </div>
    </div >
  )
}

export default CustomersPage
