import React, { forwardRef, useImperativeHandle, useState, useEffect } from 'react'
import httpClient from '../../../../httpClient'
import { toast } from 'react-toastify'
import Slider from '../../../../components/Slider'
import GoogleIcon from '@mui/icons-material/Google'
import MicrosoftIcon from '@mui/icons-material/Microsoft'
import InfoIcon from '@mui/icons-material/Info'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import { useSearchParams } from 'react-router-dom'
import './ActivitiesSettings.css'

const ActivitiesSettings = forwardRef(({ onChangeMade }, ref) => {
  const [activitiesData, setActivitiesData] = useState({
    calendarIntegrationIsActive: false,
    calendarIntegrationEmail: "",
    calendarIntegrationType: "All",
    isGoogleConnected: false,
    isMicrosoftConnected: false
  })
  const [googleAuthStatus, setGoogleAuthStatus] = useState(false)
  const [microsoftAuthStatus, setMicrosoftAuthStatus] = useState(false)
  const [searchParams] = useSearchParams()
  const [adminConsentUrl, setAdminConsentUrl] = useState("")
  const [showAdminConsentMessage, setShowAdminConsentMessage] = useState(false)

  useImperativeHandle(ref, () => ({
    saveSettings
  }))

  useEffect(() => {
    fetchActivitiesData()
    checkGoogleAuthStatus()
    checkMicrosoftAuthStatus()
    
    if (searchParams.get('adminConsentRequired') === '1') {
      setShowAdminConsentMessage(true)
      fetchAdminConsentUrl()
    }
  }, [searchParams])

  const fetchActivitiesData = async () => {
    try {
      const response = await httpClient.get(process.env.REACT_APP_API_URL + "/api/settings/calendar-integration")
      if (response.status === 200) {
        setActivitiesData(prevState => ({
          ...prevState,
          calendarIntegrationIsActive: response.data.active,
          calendarIntegrationEmail: response.data.email_address,
          calendarIntegrationType: response.data.calendar_type
        }))
      }
    } catch (error) {
      console.error('Error fetching activities data:', error)
      toast.error('Failed to load calendar integration settings')
    }
  }

  const checkGoogleAuthStatus = async () => {
    try {
      const response = await httpClient.get(
        `${process.env.REACT_APP_API_URL}/api/google/auth/status`
      )
      setGoogleAuthStatus(response.data.isConnected)
      setActivitiesData(prev => ({
        ...prev,
        isGoogleConnected: response.data.isConnected
      }))
    } catch (error) {
      console.error('Failed to check Google auth status:', error)
      toast.error('Failed to check Google Calendar connection status')
    }
  }

  const checkMicrosoftAuthStatus = async () => {
    try {
      const response = await httpClient.get(
        `${process.env.REACT_APP_API_URL}/api/microsoft/auth/status`
      )
      setMicrosoftAuthStatus(response.data.isConnected)
      setActivitiesData(prev => ({
        ...prev,
        isMicrosoftConnected: response.data.isConnected
      }))
    } catch (error) {
      console.error('Failed to check Microsoft auth status:', error)
      toast.error('Failed to check Outlook Calendar connection status')
    }
  }

  const fetchAdminConsentUrl = async () => {
    try {
      const response = await httpClient.get(
        `${process.env.REACT_APP_API_URL}/api/microsoft/auth/admin-consent/url`
      )
      setAdminConsentUrl(response.data.adminConsentUrl)
    } catch (error) {
      console.error('Failed to fetch admin consent URL:', error)
      toast.error('Failed to generate admin consent URL')
    }
  }

  const handleCopyAdminUrl = async () => {
    try {
      await navigator.clipboard.writeText(adminConsentUrl)
      toast.success('Admin consent URL copied to clipboard!')
    } catch (error) {
      console.error('Failed to copy URL:', error)
      toast.error('Failed to copy URL to clipboard')
    }
  }

  const handleSetActivityState = () => {
    setActivitiesData(prevState => ({
      ...prevState,
      calendarIntegrationIsActive: !prevState.calendarIntegrationIsActive
    }))
    onChangeMade()
  }

  const handleGoogleCalendarConnect = async () => {
    try {
      const response = await httpClient.get(
        `${process.env.REACT_APP_API_URL}/api/google/auth/url`
      )
      window.location.href = response.data.authUrl
    } catch (error) {
      toast.error('Failed to connect to Google Calendar')
      console.error('Google Calendar connection error:', error)
    }
  }

  const handleGoogleCalendarDisconnect = async () => {
    try {
      await httpClient.post(
        `${process.env.REACT_APP_API_URL}/api/google/auth/disconnect`
      )
      setGoogleAuthStatus(false)
      setActivitiesData(prev => ({
        ...prev,
        isGoogleConnected: false
      }))
      toast.success('Disconnected from Google Calendar')
    } catch (error) {
      toast.error('Failed to disconnect from Google Calendar')
      console.error('Google Calendar disconnection error:', error)
    }
  }

  const handleMicrosoftCalendarConnect = async () => {
    try {
      const response = await httpClient.get(
        `${process.env.REACT_APP_API_URL}/api/microsoft/auth/url`
      )
      window.location.href = response.data.authUrl
    } catch (error) {
      toast.error('Failed to connect to Outlook Calendar')
      console.error('Microsoft Calendar connection error:', error)
    }
  }

  const handleMicrosoftCalendarDisconnect = async () => {
    try {
      await httpClient.post(
        `${process.env.REACT_APP_API_URL}/api/microsoft/auth/disconnect`
      )
      setMicrosoftAuthStatus(false)
      setActivitiesData(prev => ({
        ...prev,
        isMicrosoftConnected: false
      }))
      toast.success('Disconnected from Outlook Calendar')
    } catch (error) {
      toast.error('Failed to disconnect from Outlook Calendar')
      console.error('Microsoft Calendar disconnection error:', error)
    }
  }

  const saveSettings = async () => {
    try {
      const response = await toast.promise(
        httpClient.post(process.env.REACT_APP_API_URL + `/api/settings/calendar-integration`, activitiesData),
        {
          pending: "Saving calendar integration...",
          success: `Calendar integration has been saved!`,
          error: "Something went wrong"
        }
      )
      console.log(response.data)
      return true
    } catch (error) {
      console.error('Error saving calendar settings:', error)
      toast.error('Failed to save calendar settings')
      return false
    }
  }

  return (
    <div className="settings_details__container" id="settings_activities">
      <h1>ACTIVITIES SETTINGS</h1>
      
      <div className="settings_forms__wrapper">
        <div className="settings_form">
          <h2>Calendar Integration</h2>
          
          <div className="settings_item--25">
            <Slider
              fontSize={"0.8rem"}
              size={1.3}
              state={activitiesData.calendarIntegrationIsActive}
              text={"Active"}
              onClickFunction={handleSetActivityState}
            />
          </div>

          {activitiesData.calendarIntegrationIsActive && (
            <div className="settings_item">
              <div className="calendar-connection">
                <h3>Google Calendar</h3>
                <div className="google-calendar-connection">
                  {googleAuthStatus ? (
                    <div className="connection-status">
                      <span className="connected-text">✓ Connected to Google Calendar</span>
                      <button
                        className="disconnect-btn"
                        onClick={handleGoogleCalendarDisconnect}
                      >
                        Disconnect
                      </button>
                    </div>
                  ) : (
                    <button
                      className="google-calendar-btn"
                      onClick={handleGoogleCalendarConnect}
                    >
                      <GoogleIcon /> Connect Google Calendar
                    </button>
                  )}
                </div>
              </div>

              <div className="calendar-connection">
                <h3>Outlook Calendar</h3>
                <div className="microsoft-calendar-connection">
                  {microsoftAuthStatus ? (
                    <div className="connection-status">
                      <span className="connected-text">✓ Connected to Outlook Calendar</span>
                      <button
                        className="disconnect-btn"
                        onClick={handleMicrosoftCalendarDisconnect}
                      >
                        Disconnect
                      </button>
                    </div>
                  ) : (
                    <>
                      <button
                        className="microsoft-calendar-btn"
                        onClick={handleMicrosoftCalendarConnect}
                      >
                        <MicrosoftIcon /> Connect Outlook Calendar
                      </button>
                      
                      {showAdminConsentMessage && adminConsentUrl && (
                        <div className="admin-consent-message">
                          <div className="admin-consent-header">
                            <InfoIcon className="info-icon" />
                            <h4>Admin Consent Required</h4>
                          </div>
                          <p>
                            Your organization requires admin consent to connect Outlook Calendar. 
                            Please share the link below with your IT administrator to grant access:
                          </p>
                          <div className="admin-consent-url">
                            <input 
                              type="text" 
                              value={adminConsentUrl} 
                              readOnly
                            />
                            <button 
                              className="copy-button"
                              onClick={handleCopyAdminUrl}
                              title="Copy URL"
                            >
                              <ContentCopyIcon />
                            </button>
                          </div>
                          <p className="admin-note">
                            Note: After admin approval, you can try connecting again.
                          </p>
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
})

export default ActivitiesSettings 