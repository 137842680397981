import React, { useState, useEffect } from "react"
import httpClient from "../httpClient"
import "./LoginPage.css"
import { Link, useNavigate, useLocation } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import { setIsMobile } from "../store/global"
import { addUserInfo } from "../store/user"
import CircularProgress from '@mui/material/CircularProgress'
import { toast } from "react-toastify"
import { changeThemeColor } from "../components/helpers/GlobalFunctions"
import { showNotificationPopup } from "../components/NotificationPopup"

function LoginPage() {
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [error, setError] = useState("")
  const [loading, setLoading] = useState(false)

  const logo_black = require("../assets/logos/Stoqup_logo_black.png")
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const isMobile = useSelector(state => state.global.isMobile)

  useEffect(() => {
    changeThemeColor("#1E1E1E")

    // Handle Microsoft admin consent response
    const params = new URLSearchParams(location.search)
    const adminConsentStatus = params.get('admin_consent')
    
    if (adminConsentStatus === 'success') {
      showNotificationPopup({
        title: 'Microsoft Integration Approved',
        message: 'Admin consent has been successfully granted. User can now log in and connect their account.',
        actionButtonText: 'OK',
        popupButtonColor: 'green',

        actionButtonOnClick: () => {
          // Clean up the URL
          const cleanUrl = window.location.pathname
          window.history.replaceState({}, document.title, cleanUrl)
        }
      })
    } else if (adminConsentStatus === 'denied') {
      showNotificationPopup({
        title: 'Microsoft Integration Denied',
        message: 'Your organization administrator has denied access to Microsoft Calendar. Please contact support if you believe this is a mistake.',
        actionButtonText: 'OK',
        popupButtonColor: 'red',
        actionButtonOnClick: () => {
          // Clean up the URL
          const cleanUrl = window.location.pathname
          window.history.replaceState({}, document.title, cleanUrl)
        }
      })
    }
  }, [location.search])

  const logInUser = async () => {
    setLoading(true)

    const data = {
      email: email,
      password: password,
      type: "dashboards"
    }
    try {
      const response = await httpClient.post(process.env.REACT_APP_API_URL + "/api/login", data)

      if (response.status === 200) {
        const userInfo = response.data
        dispatch(addUserInfo(userInfo))

        if (!userInfo.is_active) {
          toast.error("Your Stoqup account is not authorized", { theme: "dark" })
          setLoading(false)
          return
        }

        redirectToHome(userInfo, isMobile, navigate)
      } else {
        handleErrorResponse(response.status)
      }
    } catch (error) {
      console.error("Login error:", error)
      toast.error("An error occurred during login", { theme: "dark" })
    } finally {
      setLoading(false)
    }
  }

  const handleErrorResponse = (status) => {
    switch (status) {
      case 401:
        toast.error("Email and/or password not correct", { theme: "dark" })
        break
      case 403:
        toast.error("Your account is not authorized", { theme: "dark" })
        break
      case 406:
        toast.error("Your email address has not been verified yet", { theme: "dark" })
        break
      case 404:
        toast.error("Email and/or password not correct", { theme: "dark" })
        break
      default:
        console.log("Unhandled status code:", status)
    }
  }
  

  const handleEnter = (event) => {
    if (event.key === "Enter") {
      event.preventDefault()
      logInUser()
    }
  }

  const redirectToHome = (userInfo, isMobile, navigate) => {
    let dashboardPath

    if (userInfo.type === "admin") {
      dashboardPath = isMobile ? "/m/home" : "/admin/home"
    } else if (userInfo.type === "manager") {
      dashboardPath = isMobile ? "/m/home" : "/brandowner/home"
    } else if (userInfo.type === "salesforce") {
      dashboardPath = isMobile ? "/m/home" : "/salesforce/home"
    } else {
      toast.error("Your Stoqup account is not authorized", { theme: "dark" })
      return
    }

    navigate(dashboardPath)
  }

  return (
    <div className="body-loginpage">
      <img className="logo" src={logo_black} alt="stoqup logo black" />
      <form className={`login-form ${isMobile ? "is-mobile" : ""}`}>
        <input
          value={email}
          onChange={e => setEmail(e.target.value)}
          placeholder='EMAIL'
          onKeyDown={handleEnter}
        />
        <input
          value={password}
          onChange={e => setPassword(e.target.value)}
          type='password'
          placeholder='PASSWORD'
          onKeyDown={handleEnter}
        />

        <button type='button' className='button-login' onClick={logInUser}><div className={`${loading ? "hide" : ""}`}>LOG IN</div>{loading && <CircularProgress color="inherit" size={"2.3rem"} />}</button>
        <div className='login-links'>
          <Link to='/forgotpassword'>Forgot password</Link>
          <Link to='/signup'>Signup</Link>
        </div>
      </form>
    </div>
  )
}

export default LoginPage