import "./DropDownInputCustomer.css"
import AddIcon from '@mui/icons-material/Add'
import React, { useRef, useState, useEffect } from "react"
import { normalizeString } from "./helpers/GlobalFunctions"
import PopupWindowCreateNewCustomer from "./PopupWindowCreateNewCustomer"

function DropDownInputCustomer({ idList, value, setValue, onClickItem, listArray, placeholderText, inherit, fetchCustomers }) {
  const [dropDownIsOpen, setdropDownIsOpen] = useState(false)
  const [selectedCustomer, setSelectedCustomer] = useState(value)
  const [searchValue, setSearchValue] = useState("")
  const [newCustomerPopupIsActive, setNewCustomerPopupIsActive] = useState(false)
  const ref = useRef(null)
  let key = 0

  const onClickOutside = () => {
    const dropdownList = document.querySelectorAll(`#${idList}`)

    Array.from(dropdownList).forEach(dropdown => {
      dropdown.classList.remove("active")
    })
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        onClickOutside && onClickOutside()
      }
    }
    document.addEventListener("click", handleClickOutside, true)

    return () => {
      document.removeEventListener("click", handleClickOutside, true)
    }
  }, [onClickOutside])

  useEffect(() => {
    setSelectedCustomer(value)
  }, [value])

  const handleOnChangeInput = (event) => {
    setdropDownIsOpen(true)
    const rawValue = event.target.value
    const normalizedValue = normalizeString(rawValue)
    setSelectedCustomer(rawValue)
    setSearchValue(normalizedValue)

  }

  const handleOnClickItem = (event) => {
    setdropDownIsOpen(!dropDownIsOpen)
    onClickItem(event)
  }

  const handleOnClickCreateCustomer = () => {
    setNewCustomerPopupIsActive(true)
    setdropDownIsOpen(false)
  }

  const handleNewCustomerCreated = (customer) => {
    setValue(customer)
  }

  return (
    <>
      <div className="dropdown__container customer flex--vert" ref={ref} >
        <input className="input_field__input_box--text input-dropdown" id="visits__input-field-customer" placeholder={placeholderText} value={selectedCustomer} onChange={handleOnChangeInput} autoComplete="off" />
        <ul className={`input_field__dropdown ${dropDownIsOpen ? "active" : ""} ${inherit && "inherit"}`} id={idList} key={key++}>
          <button className="button_create_new" onClick={handleOnClickCreateCustomer}>Create new customer <AddIcon /></button>
          {
            listArray
              .filter(item => {
                const customer = selectedCustomer || ''

                return (
                  (item.business_name_normalized && item.business_name_normalized.includes(searchValue)) ||
                  (item.first_name_normalized && item.first_name_normalized.includes(searchValue)) ||
                  (item.last_name_normalized && item.last_name_normalized.includes(searchValue))
                )
              })
              .map(item => {
                return (
                  <li className="customer-list-dropdown--item customer" key={key++} onClick={handleOnClickItem} id={item.id}>
                    <div>{item.type == "B2C" ? `${item.first_name} ${item.last_name}` : item.business_name}</div>
                    <div className="txt--light-medium-gray">|</div>
                    <div className="txt--light-medium-gray">{item.delivery_address_place}</div>
                    <div className="txt--light-medium-gray">|</div>
                    <div className="txt--light-medium-gray">{item.type}</div>
                  </li>
                )
              })
          }
        </ul>
      </div>

      <PopupWindowCreateNewCustomer
        isActive={newCustomerPopupIsActive}
        setIsActive={setNewCustomerPopupIsActive}
        onCustomerCreated={handleNewCustomerCreated}
        fetchCustomers={fetchCustomers}
      />
    </>
  )
}

export default DropDownInputCustomer

DropDownInputCustomer.defaultProps = {
  inherit: false,
}